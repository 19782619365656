.wf-theme-default .avue--detail .el-col {
  margin-bottom: 0 !important;
}
.wf-theme-default .avue--detail .hover-row td {
  background-color: #fff !important;
}
.wf-theme-default .avue--detail .avue-group__header {
  padding-left: 10px;
}
.wf-theme-default .avue--detail .el-collapse-item__header {
  margin-bottom: 0;
  background-color: #f3f3f3 !important;
}
.wf-theme-default .avue--detail .el-input.is-disabled .el-input__inner,
.wf-theme-default .avue--detail .el-textarea.is-disabled .el-textarea__inner,
.wf-theme-default .avue--detail .el-range-editor.is-disabled,
.wf-theme-default .avue--detail .el-range-editor.is-disabled input {
  color: #606266;
  background-color: #fff;
  padding-left: 0;
  cursor: default;
}
.wf-theme-default .avue--detail .el-input-number__decrease,
.wf-theme-default .avue--detail .el-input-number__increase {
  display: none;
}
.wf-theme-default .avue--detail .el-input-group__append,
.wf-theme-default .avue--detail .el-input-group__prepend {
  background-color: transparent;
  border: none;
}
.wf-theme-default .avue--detail .el-input__suffix {
  display: none;
}
.wf-theme-default .avue--detail .el-input__inner,
.wf-theme-default .avue--detail .el-textarea__inner {
  border: none;
}
.wf-theme-default .avue--detail .el-input__inner::-webkit-input-placeholder,
.wf-theme-default .avue--detail .el-textarea__inner::-webkit-input-placeholder {
  color: transparent !important;
}
.wf-theme-default .avue--detail .el-input__inner::-moz-placeholder,
.wf-theme-default .avue--detail .el-textarea__inner::-moz-placeholder {
  color: transparent !important;
}
.wf-theme-default .avue--detail .el-input__inner::-moz-placeholder,
.wf-theme-default .avue--detail .el-textarea__inner::-moz-placeholder {
  color: transparent !important;
}
.wf-theme-default .avue--detail .el-input__inner::-ms-input-placeholder,
.wf-theme-default .avue--detail .el-textarea__inner::-ms-input-placeholder {
  color: transparent !important;
}
.wf-theme-default .avue--detail .el-input__inner::-ms-input-placeholder,
.wf-theme-default .avue--detail .el-textarea__inner::-ms-input-placeholder {
  color: transparent !important;
}
.wf-theme-default .avue--detail .avue-checkbox__all {
  display: none;
}
.wf-theme-default .avue--detail .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  border-color: #409eff;
}
.wf-theme-default .avue--detail .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #fff;
}
.wf-theme-default .avue--detail .el-radio__input.is-disabled.is-checked .el-radio__inner {
  background-color: #409eff;
  border-color: #409eff;
}
.wf-theme-default .avue--detail .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background-color: #fff;
}
.wf-theme-default .avue--detail .el-checkbox__input.is-disabled + span.el-checkbox__label,
.wf-theme-default .avue--detail .el-radio__input.is-disabled + span.el-radio__label {
  color: #606266;
}
.wf-theme-default .avue--detail .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.wf-theme-default .avue--detail .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}
.wf-theme-default .avue--detail .el-row {
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
}
.wf-theme-default .avue--detail .el-col {
  padding: 0 !important;
  border-bottom: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
.wf-theme-default .avue--detail .el-form-item {
  margin: 0;
  background: #fafafa;
}
.wf-theme-default .avue--detail .el-form-item__label,
.wf-theme-default .avue--detail .el-form-item__content {
  padding: 2px 0;
}
.wf-theme-default .avue--detail .el-form-item__label {
  padding: 0 10px;
  color: #909399;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.wf-theme-default .avue--detail .el-tag {
  margin-left: 0 !important;
  margin-right: 6px !important;
}
.wf-theme-default .avue--detail .el-form-item__content {
  border-left: 1px solid #ebeef5;
  padding-left: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #fff;
}
.wf-theme-default .avue--detail__column .el-form-item {
  background-color: #fff;
}
.wf-theme-default .avue--detail__column .el-form-item__label {
  padding-right: 12px;
}
.wf-theme-default .avue--detail__column .el-form-item__content {
  padding-left: 0;
  border-left: none;
}